import { createContext, useContext, useEffect, useMemo, useState } from "react";
import type { PropsWithChildren } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import { R } from "common/request";
import { useRequest } from "ahooks";
import { parseCookies } from "nookies";
import { IUserInfo } from "common/interface/login";
import { menuData } from "@/components/menu/const";

// 全局信息
export const GlobalContext = createContext({
  userInfo: {} as IUserInfo,
  roleIds: [] as number[],
  isStu: false,
  deleteConfirm: () => new Promise((resolve, reject) => {}),
  setUser: (params: IUserInfo) => {},
  commonInfo: {} as any,
  quotaNotice: false,
  setQuotaNotice: (params: any) => {},
  posts: [],
  menuList: [] as any[],
  statsInfo: {} as any,
  setStatsInfo: (params: any) => {}
});

export interface GlobalProps {
  userInfo: IUserInfo;
}

export const GlobalProvider = ({ children, userInfo }: PropsWithChildren<GlobalProps>) => {
  const [modal, contextHolder] = Modal.useModal();
  const [loginInfo, setLoginInfo] = useState();
  const [userInfoState, setUserInfoState] = useState(userInfo);
  const [commonInfo, setCommonInfo] = useState<any>({});
  const { token } = parseCookies(null, "token");
  const [quotaNotice, setQuotaNotice] = useState<any>(false);
  const [showCoachTab, setShowCoachTab] = useState<boolean>(true);
  const [statsInfo, setStatsInfo] = useState<any>({});

  useRequest(() => R("common/settings"), {
    onSuccess: res => setCommonInfo(res.data),
    ready: Boolean(token)
  });

  useEffect(() => {
    let cacheInfo: any = localStorage.getItem("loginInfo");
    if (cacheInfo && JSON.parse(cacheInfo)) {
      cacheInfo = JSON.parse(JSON.parse(cacheInfo));
      setLoginInfo(cacheInfo as any);
    }
    const no_tutor = localStorage.getItem("showCoachTab");
    if (no_tutor && JSON.parse(no_tutor)) {
      const showTab = JSON.parse(JSON.parse(no_tutor));
      const showTabShow = showTab?.show ?? true;
      setShowCoachTab(cacheInfo?.role?.id > 3 ? showTabShow : true);
    } else {
      setShowCoachTab(true);
    }
  }, [children]);

  const newUserInfo = loginInfo || userInfo;
  const isStu = newUserInfo?.role?.id === 4;

  const posts = useMemo(
    () =>
      commonInfo?.industry?.find((item: any) => item.id === userInfoState?.company?.industry)
        ?.posts || [],
    [userInfoState, commonInfo]
  );

  const menuList = useMemo(
    () =>
      menuData.filter(item =>
        item.key === "/coach"
          ? (
              commonInfo?.enabled_course_types ?? userInfoState?.settings?.enabled_course_types
            )?.includes(4) && showCoachTab
          : !!item.filter?.(userInfoState?.role?.id)
      ) || [],
    [commonInfo, userInfoState, showCoachTab]
  );

  useEffect(() => {
    setUserInfoState(newUserInfo);
  }, [newUserInfo]);

  const deleteConfirm = async (): Promise<boolean> =>
    new Promise((resolve, reject) => {
      modal.confirm({
        title: "重要操作",
        content: "删除后不可恢复，请确认删除？",
        icon: <ExclamationCircleFilled />,
        keyboard: false,
        cancelText: "取消",
        onOk: () => resolve(true),
        onCancel: () => reject()
      });
    });

  return (
    <GlobalContext.Provider
      value={{
        userInfo: userInfoState,
        roleIds: [userInfo?.role?.id],
        isStu,
        deleteConfirm,
        setUser: info => setUserInfoState(info),
        commonInfo,
        quotaNotice,
        setQuotaNotice,
        posts,
        menuList,
        statsInfo,
        setStatsInfo
      }}
    >
      {contextHolder}
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => useContext(GlobalContext);
